import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import {requestPendingGroupUpdating, requestPendingMigrations, requestPendingTerminations} from '../../../actions/adminPortal';
import {showToast} from '../../../actions/shared';
import {getProfileInfo} from '../../../selectors/general';
import {convertDateToTimeZone, formatDate, isEmpty} from '../../../utils';
import './index.scss';

const PendingGroupNotes = ({groupAlias, isTerminations}) => {
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector(getProfileInfo);
    const [notes, setNotes] = useState({message: '', updated_at: null, updated_by: null, error: null});
    const notesKey = isTerminations ? 'pending_termination_notes' : 'pending_migration_notes';

    useEffect(() => {
        (async () => {
            const requestFunc = isTerminations ? requestPendingTerminations : requestPendingMigrations;
            const {pendingTerminations, pendingMigrations} = await dispatch(requestFunc({company_alias: groupAlias, limit: 1}));
            const pendingGroupList = isTerminations ? pendingTerminations : pendingMigrations;
            const pendingInfo = pendingGroupList[0] || {};

            if (isEmpty(pendingInfo[notesKey])) {
                return;
            }

            setNotes(pendingInfo[notesKey]);
        })();
    }, []);

    const onUpdateNotes = async ({target}) => {
        const query = {
            companyAlias: groupAlias,
            [notesKey]: {
                message: target?.value,
                updated_at: new Date().toISOString(),
                updated_by: userEmail
            }
        };
        const {data, isSuccess} = await dispatch(requestPendingGroupUpdating(query));

        if (!isSuccess) {
            setNotes(state => ({...state, error: data?.message}));

            return false;
        }

        setNotes(data?.[notesKey]);
        dispatch(showToast({content: 'Changes saved.'}));
    };

    return (
        <div className='pending-group-notes mt-20'>
            {notes?.error && <Text type={TEXT_TYPES.helper} className='ml-10 mb-3'><span data-testid='notes-error' className='notes-error'><Icon type={ICON_TYPES.alert} className='mr-3'/> Notes not saved. {notes?.error}.</span></Text>}
            <Textarea value={notes.message}
                data-testid='pending-group-note'
                label='Notes'
                description={notes.updated_by && notes.updated_at
                    && `Last updated ${formatDate(convertDateToTimeZone(notes.updated_at), 'MM/dd/yyyy H:mm')} CST by ${notes.updated_by}`}
                maxLength={200}
                onChange={({target}) => setNotes(state => ({...state, message: target?.value}))}
                onBlur={onUpdateNotes}
                isValid={!notes?.error}
                isComponentErrorHandling
                isTouched/>`
        </div>
    );
};

PendingGroupNotes.propTypes = {
    groupAlias: PropTypes.string.isRequired,
    isTerminations: PropTypes.bool
};

export {PendingGroupNotes as TestablePendingGroupNotes};
export default PendingGroupNotes;
